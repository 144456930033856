import React from "react";
import pathOr from "@ramda/pathor";
import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { List, Map } from "immutable";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  ServiceRequestFormHelpers,
  ServiceRequestFormValues,
  ServiceRequestProps,
} from "types/StorageForms/ServiceRequest";
import * as yup from "yup";
import Icon, { RasterIcon } from "../../../../components/Icon/Icon";
import { IconType } from "../../../../enums";
import {
  closeRightPanel,
  openRightPanel,
} from "../../../../reduxFolder/reducers/rightPanel";
import {
  cancelRequestInventoryServiceRequest,
  cancelServiceRequest,
  completeRequestTransferInventoryItems,
  completeRequestTransferShipments,
  createRequestInventoryShipment,
  createRequestTransferInventoryItems,
  createRequestTransferShipments,
  createServiceRequest,
  requestNames,
} from "../../../../reduxFolder/reducers/serviceRequests";
import {
  addServiceRequest,
  getShipment,
  getStorage,
} from "../../../../reduxFolder/reducers/storage";
import {
  requestsSelectServiceTypes,
  requestsSelectTransferRequests,
} from "../../../../reduxFolder/selectors/serviceRequestsSelectors";
import {
  storageSelectDetailedLoading,
  storageSelectItems,
  storageSelectSelected,
} from "../../../../reduxFolder/selectors/storageSelectors";
import { isTransferringState } from "../../../../utils/common";
import { toastResponseError } from "../../../../utils/responseMessageHelper";
import { CustomRasterIcon } from "./ServiceRequest.styles";

export function getEnabledRequestTypes(serviceRequestsTypes: any, t: any) {
  let retVal = [];

  // Photo request
  const isPhotoRequestEnabled = serviceRequestsTypes.find(
    (x: any) => x.get("type") === "additional photos",
  );
  if (isPhotoRequestEnabled) {
    const converted = {
      key: requestNames["additional photos"],
      title: t("serviceRequests.additionalPhotos.title"),
      ...isPhotoRequestEnabled.toJS(),
    };
    if (!converted.icon) {
      converted.icon = {};
    }
    if (!converted.icon.url) {
      converted.icon.element = <Icon type={IconType.AdditionalPhotos} />;
    }

    retVal.push(converted);
  }

  // Custom request
  const isCustomRequestEnabled = serviceRequestsTypes.find(
    (x: any) => x.get("type") === "custom",
  );
  if (isCustomRequestEnabled) {
    const converted = {
      key: requestNames["custom"],
      title: t("serviceRequests.serviceByInstruction.title"),
      ...isCustomRequestEnabled.toJS(),
    };
    if (!converted.icon) {
      converted.icon = {};
    }
    if (!converted.icon.url) {
      converted.icon.element = <Icon type={IconType.ServiceByInstruction} />;
    }

    retVal.push(converted);
  }

  // Remaining requests
  const remainingRequests = serviceRequestsTypes.filter(
    (x: any) =>
      x.get("type") !== "additional photos" && x.get("type") !== "custom",
  );

  const groupedRequests = remainingRequests
    .filter((x: any) => !!x.get("group"))
    .groupBy((x: any) => x.get("group"));

  for (const [key, group] of groupedRequests) {
    retVal.push({
      key,
      groupKeys: group
        .map(
          (x: any) => requestNames[x.get("type") as keyof typeof requestNames],
        )
        .toArray(),
      title: key,
      icon: {
        element: (
          <CustomRasterIcon
            icon={
              group
                .find((x: any) => x.get("icon")?.get("url"))
                ?.get("icon")
                .get("url") ?? ""
            }
          />
        ),
      },
      groupRequests: group,
    });
  }

  return retVal;
}

const cancelableStates = [
  "translated",
  "pending_translation",
  "pending_moderation",
  "warehouse_requested",
  "pending_requestInventoryShipment",
];

const isUnprocessedRequest = (serviceRequests = []) =>
  serviceRequests.find((name) => name === "unprocessed");

const getRequestInventoryInfo = (unpackingTask: any) => {
  const isRequestActive = unpackingTask.get("state") === "requested";

  return Map({
    hasRequest: isRequestActive,
    isSubmitHidden: false,
    isRequestCancelable: isRequestActive,
    processed: false,
    service_request_type: Map({
      type: "requestInventoryShipment",
    }),
    state: "pending_requestInventoryShipment",
  });
};

const getRequestInfoByGroup = (requests = [], requestType: string) => {
  const request =
    requests.find(
      (request: any) =>
        request.getIn(["service_request_type", "group"]) === requestType,
      null,
    ) || Map();

  const hasRequest = !!request.get("id");
  const isRequestCancelable =
    hasRequest && cancelableStates.includes(request.get("state") as string);
  const processed = hasRequest && request.get("processed");

  const isSubmitHidden = hasRequest ? !isRequestCancelable || processed : false;

  return request.merge(
    Map({
      hasRequest,
      isSubmitHidden,
      isRequestCancelable,
      processed,
    }),
  );
};

const getRequestInfo = (
  requests = [],
  requestType: string,
  useGroupRequests = false,
) => {
  const request = useGroupRequests
    ? requests.find(
        (request: any) =>
          (request
            .getIn(["service_request_type", "type"])
            .toLowerCase() as keyof typeof requestNames) === requestType,
        null,
      ) || Map()
    : requests.find(
        (request: any) =>
          requestNames[
            request
              .getIn(["service_request_type", "type"])
              .toLowerCase() as keyof typeof requestNames
          ] === requestType,
        null,
      ) || Map();

  const hasRequest = !!request.get("id");
  const isRequestCancelable =
    hasRequest && cancelableStates.includes(request.get("state") as string);
  const processed = hasRequest && request.get("processed");

  const isSubmitHidden = hasRequest ? !isRequestCancelable || processed : false;

  return request.merge(
    Map({
      hasRequest,
      isSubmitHidden,
      isRequestCancelable,
      processed,
    }),
  );
};

const handleRequestSubmit = (
  values: ServiceRequestFormValues,
  {
    setSubmitting,
    props: {
      panelData,
      openRightPanel,
      addServiceRequest,
      createServiceRequest,
      cancelServiceRequest,
      cancelRequestInventoryServiceRequest,
      createRequestInventoryShipment,
      createRequestTransferShipments,
      createRequestTransferInventoryItems,
      completeRequestTransferShipments,
      completeRequestTransferInventoryItems,
      getShipment,
      getStorage,
      closeRightPanel,
      serviceTypes = List(),
      selectedParcels,
    },
  }: ServiceRequestFormHelpers,
) => {
  const requestType = panelData.get("requestType");

  if (requestType === "requestTransferShipment") {
    const id = panelData.get("id");
    const partnerTransferId = values.partnerTransferId ?? "";
    const isInventoryItemVariant = panelData.get("isInventoryItemVariant");
    const state = panelData.get("state");
    const isActiveTransferRequest = isTransferringState(state);

    if (isActiveTransferRequest) {
      if (isInventoryItemVariant) {
        return completeRequestTransferInventoryItems({
          codes: [partnerTransferId],
        })
          .then(() => {
            getStorage();
            closeRightPanel();
            setSubmitting(false);
          })
          .catch((error: Error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
      } else {
        return completeRequestTransferShipments({
          codes: [partnerTransferId],
        })
          .then(() => {
            getStorage();
            closeRightPanel();
            setSubmitting(false);
          })
          .catch((error: Error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
      }
    } else {
      if (isInventoryItemVariant) {
        const transferInventoryItemId = panelData.get(
          "transferInventoryItemId",
        );
        const selectedInventoryItems = panelData.get("selectedInventoryItems");
        const shipment = panelData.get("shipment");

        const filteredItems =
          selectedInventoryItems && selectedInventoryItems.length
            ? shipment
                .get("items")
                .filter((x: any) =>
                  selectedInventoryItems.includes(x.get("id")),
                )
            : shipment
                .get("items")
                .filter((x: any) => transferInventoryItemId === x.get("id"));

        const ids = filteredItems.map((x: any) => x.get("id"));
        const skuList = filteredItems.map((x: any) => x.get("sku"));

        return createRequestTransferInventoryItems({
          sku: skuList,
          ids,
          partnerTransferId,
        })
          .then(() => {
            setSubmitting(false);
          })
          .catch((error: Error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
      } else {
        const bulkTransferIds = selectedParcels?.toJS();
        const ids = bulkTransferIds?.length ? bulkTransferIds : [id];

        return createRequestTransferShipments({
          ids,
          partnerTransferId,
        })
          .then(() => {
            setSubmitting(false);
          })
          .catch((error: Error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
      }
    }
  } else if (requestType === "requestInventoryShipment") {
    const shipmentId = panelData.get("id");
    const id = panelData
      .get("requestInventoryShipmentData", Map())
      .get("items", List())
      .get(0, Map())
      .get("id");

    const unpackingServiceRequest = panelData
      .get("requestInventoryShipmentData", Map())
      .get("unpacking_task", Map());
    const status = unpackingServiceRequest.get("state");

    if (status === "requested") {
      cancelRequestInventoryServiceRequest({
        taskId: unpackingServiceRequest.get("id"),
      })
        .then(() => {
          getShipment(shipmentId).then(() => {
            openRightPanel("STORAGE_ITEM", panelData);
          });
        })
        .catch((error) => {
          toastResponseError(error);
          setSubmitting(false);
        });
    } else {
      createRequestInventoryShipment({ id })
        .then(() => {
          getShipment(shipmentId).then(() => {
            openRightPanel("STORAGE_ITEM", panelData);
          });
        })
        .catch((error: Error) => {
          toastResponseError(error);
          setSubmitting(false);
        });
    }
  } else {
    if (!Object.values(requestNames).some((x) => x === requestType)) {
      const currentRequest = getRequestInfoByGroup(
        panelData.get("requests"),
        requestType,
      );
      const hasRequest = currentRequest.get("hasRequest");

      if (hasRequest) {
        const data = {
          itemId: panelData.get("id"),
          requestId: currentRequest.get("id"),
        };

        cancelServiceRequest(data)
          .then((response) => {
            const value = pathOr(Map(), ["value"], response);
            const data = value.get("data");
            data &&
              addServiceRequest(Map({ packageId: panelData.get("id"), data }));
            openRightPanel("STORAGE_ITEM", panelData);
          })
          .catch((error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
      } else {
        const serviceInfo = serviceTypes.find(
          (service: any) => service.get("type") === values.selectedGroupOption,
        );

        const data = {
          itemId: panelData.get("id"),
          service_request_type_id: serviceInfo.get("id"),
          user_comment: values.comment,
        };

        createServiceRequest(data)
          .then((response) => {
            const value = pathOr(Map(), ["value"], response);
            const data = value.get("data");
            data &&
              addServiceRequest(Map({ packageId: panelData.get("id"), data }));
            openRightPanel("STORAGE_ITEM", panelData);
          })
          .catch((error) => {
            toastResponseError(error);
            setSubmitting(false);
          });
      }
    } else {
      const currentRequest = panelData
        .get("requests")
        .find(
          (request: any) =>
            requestNames[
              request
                .getIn(["service_request_type", "type"])
                .toLowerCase() as keyof typeof requestNames
            ] === requestType,
        );

      const serviceInfo = serviceTypes.find(
        (service: any) =>
          requestNames[service.get("type") as keyof typeof requestNames] ===
          requestType,
      );

      const onSubmit = currentRequest
        ? cancelServiceRequest
        : createServiceRequest;

      const data = currentRequest
        ? { itemId: panelData.get("id"), requestId: currentRequest.get("id") }
        : {
            itemId: panelData.get("id"),
            service_request_type_id: serviceInfo.get("id"),
            user_comment: values.comment,
          };

      onSubmit(data)
        .then((response) => {
          const value = pathOr(Map(), ["value"], response);
          const data = value.get("data");
          data &&
            addServiceRequest(Map({ packageId: panelData.get("id"), data }));
          openRightPanel("STORAGE_ITEM", panelData);
        })
        .catch((error) => {
          toastResponseError(error);
          setSubmitting(false);
        });
    }
  }
};

const withConnect = connect(
  createStructuredSelector({
    serviceTypes: requestsSelectServiceTypes,
    isLoading: storageSelectDetailedLoading,
    transferRequests: requestsSelectTransferRequests,
    selectedParcels: storageSelectSelected,
    storageItems: storageSelectItems,
  }),
  {
    openRightPanel,
    closeRightPanel,
    addServiceRequest,
    createServiceRequest,
    cancelServiceRequest,
    cancelRequestInventoryServiceRequest,
    createRequestInventoryShipment,
    createRequestTransferShipments,
    createRequestTransferInventoryItems,
    completeRequestTransferShipments,
    completeRequestTransferInventoryItems,
    getShipment,
    getStorage,
  },
);

export {
  handleRequestSubmit,
  withConnect,
  requestNames,
  getRequestInfo,
  getRequestInventoryInfo,
  cancelableStates,
  isUnprocessedRequest,
};

export const serviceRequestFormik = ({
  t,
  ...props
}: ServiceRequestProps & { t: TFunction }) => ({
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: {
    comment: "",
  },
  validationSchema: () => {
    const requestType = props.panelData.get("requestType");

    if (
      requestType === "requestInventoryShipment" ||
      requestType === "additionalPhotos"
    ) {
      return yup.object({});
    } else if (requestType === "requestTransferShipment") {
      const isClientTransferring = isTransferringState(
        props.panelData.get("state"),
      );

      if (isClientTransferring) {
        return yup.object({
          partnerTransferId: yup
            .string()
            .required(
              t("error.required", { field: t("common.partnerTransferId") }),
            ),
        });
      }

      return yup.object({
        partnerTransferId: yup
          .number()
          .required(
            t("error.required", { field: t("common.partnerTransferId") }),
          ),
      });
    } else if (requestType === "serviceByInstruction") {
      const hasRequest = getRequestInfo(
        props.panelData.get("requests"),
        requestType,
      ).get("hasRequest");

      return hasRequest
        ? yup.object({})
        : yup.object({
            comment: yup
              .string()
              .trim()
              .required(t("error.required", { field: t("common.comment") })),
          });
    } else {
      const hasRequest = getRequestInfoByGroup(
        props.panelData.get("requests"),
        requestType,
      ).get("hasRequest");

      return hasRequest
        ? yup.object({})
        : yup.object({
            selectedGroupOption: yup
              .string()
              .required(
                t("error.required", { field: t("error.requiredField") }),
              ),
          });
    }
  },
  onSubmit: (
    values: ServiceRequestFormValues,
    formikBag: FormikHelpers<ServiceRequestFormValues>,
  ) => handleRequestSubmit(values, { props, ...formikBag }),
});
