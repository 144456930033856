import { fromJS } from 'immutable';
import { combineActions, createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = fromJS({
  isLoading: false,
  types: null,
  transferRequests: {},
});

// const
export const GET_TYPES = 'serviceRequest/GET_TYPES';
export const GET_SHIPMENT_TYPE = 'serviceRequest/GET_SHIPMENT_TYPE';
export const CREATE_REQUEST = 'serviceRequest/CREATE_REQUEST';
export const CANCEL_REQUEST = 'serviceRequest/CANCEL_REQUEST';
export const CANCEL_REQUEST_INVENTORY_REQUEST =
  'serviceRequest/CANCEL_REQUEST_INVENTORY_REQUEST';
export const requestNames = {
  'additional photos': 'additionalPhotos',
  custom: 'serviceByInstruction',
  requestinventoryshipment: 'requestInventoryShipment',
};
export const CREATE_REQUEST_INVENTORY_SHIPMENT =
  'serviceRequest/CREATE_REQUEST_INVENTORY_SHIPMENT';

// Transfer
export const CREATE_REQUEST_TRANSFER_SHIPMENT =
  'serviceRequest/CREATE_REQUEST_TRANSFER_SHIPMENT';
export const CREATE_REQUEST_TRANSFER_INVENTORY_ITEMS =
  'serviceRequest/CREATE_REQUEST_TRANSFER_INVENTORY_ITEMS';
export const COMPLETE_REQUEST_TRANSFER_SHIPMENTS =
  'serviceRequest/COMPLETE_REQUEST_TRANSFER_SHIPMENTS';
export const COMPLETE_REQUEST_TRANSFER_INVENTORY_ITEMS =
  'serviceRequest/COMPLETE_REQUEST_TRANSFER_INVENTORY_ITEMS';

// action creators
export const getRequestTypes = createAction(
  GET_TYPES,
  api.serviceRequests.getRequestTypes,
);

export const getShipmentRequestType = createAction(
  GET_SHIPMENT_TYPE,
  api.serviceRequests.getShipmentRequestType,
);

export const createServiceRequest = createAction(
  CREATE_REQUEST,
  api.serviceRequests.createServiceRequest,
);

export const cancelServiceRequest = createAction(
  CANCEL_REQUEST,
  api.serviceRequests.cancelServiceRequest,
);

export const cancelRequestInventoryServiceRequest = createAction(
  CANCEL_REQUEST_INVENTORY_REQUEST,
  api.serviceRequests.cancelRequestInventoryServiceRequest,
);

export const createRequestInventoryShipment = createAction(
  CREATE_REQUEST_INVENTORY_SHIPMENT,
  api.serviceRequests.createRequestInventoryShipment,
);

export const createRequestTransferShipments = createAction(
  CREATE_REQUEST_TRANSFER_SHIPMENT,
  ({ ids, partnerTransferId }) => ({
    promise: api.serviceRequests.createRequestTransferShipments({
      ids,
      partnerTransferId,
    }),
    data: { ids, partnerTransferId },
  }),
  // This will be passed as meta to reducer (including SUCCESS and ERROR actions)
  ({ ids, partnerTransferId }) => ({
    ids,
    partnerTransferId,
  }),
);

export const createRequestTransferInventoryItems = createAction(
  CREATE_REQUEST_TRANSFER_INVENTORY_ITEMS,
  ({ ids, partnerTransferId, sku }) => ({
    promise: api.serviceRequests.createRequestTransferInventoryItem({
      sku,
      partnerTransferId,
    }),
    data: { sku, partnerTransferId },
  }),
  // This will be passed as meta to reducer (including SUCCESS and ERROR actions)
  ({ ids, sku, partnerTransferId }) => ({
    sku,
    ids,
    partnerTransferId,
  }),
);

export const completeRequestTransferShipments = createAction(
  COMPLETE_REQUEST_TRANSFER_SHIPMENTS,
  ({ codes }) => ({
    promise: api.serviceRequests.completeRequestTransferShipments({
      codes,
    }),
    data: { codes },
  }),
);

export const completeRequestTransferInventoryItems = createAction(
  COMPLETE_REQUEST_TRANSFER_INVENTORY_ITEMS,
  ({ codes }) => ({
    promise: api.serviceRequests.completeRequestTransferInventoryItems({
      codes,
    }),
    data: { codes },
  }),
);

// helpers
const normalizeServiceRequests = (requests) =>
  requests.map((request) =>
    request
      .set('typeDisplayName', request.get('type'))
      .update('type', (type) => type.toLowerCase()),
  );

const serviceRequestsReducer = handleActions(
  {
    [`${GET_TYPES}_REQUEST`]: (state) => state.set('isLoading', true),

    [`${GET_TYPES}_SUCCESS`]: (state, { payload }) =>
      state
        .set('types', normalizeServiceRequests(payload.get('data')))
        .set('isLoading', true),

    [`${GET_SHIPMENT_TYPE}_SUCCESS`]: (state, { payload }) =>
      state
        .set('types', normalizeServiceRequests(payload.get('data')))
        .set('isLoading', true),

    [combineActions(
      `${CREATE_REQUEST_TRANSFER_SHIPMENT}_SUCCESS`,
      `${CREATE_REQUEST_TRANSFER_INVENTORY_ITEMS}_SUCCESS`,
    )]: (state, { payload, meta }) => {
      const ids = meta.ids;
      const transferCode = payload.get('data').get('code');

      return state.set('transferRequests', {
        ...state.transferRequests,
        ...ids.reduce(
          (a, id) => ({
            ...a,
            [id]: {
              id,
              status: 'requested',
              transferCode,
            },
          }),
          {},
        ),
      });
    },

    [`${GET_TYPES}_ERROR`]: (state) => state.set('isLoading', false),
  },
  initialState,
);

export default serviceRequestsReducer;
