import React, { FC, useCallback, useEffect } from "react";
import CustomsDeclarationV2 from "components/CustomDeclaration/CustomsDeclarationV2";
import ParcelIcon from "components/Icon/icons/parcel.svg";
import {
  BackButton,
  Content,
  ContentWrapper,
  Heading,
  LeftArrowIcon,
} from "containers/RightPanel/RightPanel.styles";
import { format } from "date-fns";
import { ButtonColor } from "enums/Button";
import { IconType } from "enums/Icon";
import { List, Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { CustomInformationDto } from "types/api/cart";
import { OpenRightPanel } from "types/common/rightPanel";
import ImageViewer2 from "../../../../components/ImageViewer2/ImageViewer2";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { getRequestTypes } from "../../../../reduxFolder/reducers/serviceRequests";
import {
  getAddonsMethods,
  getConsolidationAddonsMethods,
} from "../../../../reduxFolder/reducers/services";
import { createCustomsDeclaration } from "../../../../reduxFolder/reducers/storage";
import { authSelectUnits } from "../../../../reduxFolder/selectors/globalSelectors";
import {
  historySelectDetailedShipment,
  historySelectDetailedShipmentLoading,
} from "../../../../reduxFolder/selectors/historySelector";
import {
  outgoingSelectDetailedShipment,
  outgoingSelectDetailedShipmentLoading,
} from "../../../../reduxFolder/selectors/outgoingSelectors";
import { requestsSelectServiceTypes } from "../../../../reduxFolder/selectors/serviceRequestsSelectors";
import { servicesSelectAddons } from "../../../../reduxFolder/selectors/servicesSelectors";
import {
  storageSelectDetailedLoading,
  storageSelectDetailedShipment,
} from "../../../../reduxFolder/selectors/storageSelectors";
import { getFnsLocale } from "../../../../translations/date-fns-locale";
import { toastResponseError } from "../../../../utils/responseMessageHelper";
import Tab from "../../../RightPanel/components/Tab";
import TabsView from "../../../RightPanel/components/TabsView";
import InventoryItemComment from "../InventoryItemComment/InventoryItemComment";
import { InventoryItemCommentSkeleton } from "../InventoryItemComment/InventoryItemCommentSkeleton";
import ServicesButtons from "../ServiceButtons/ServicesButtons";
import ShipmentComment from "../ShipmentComment/ShipmentComment";
import {
  Container,
  ContentContainer,
  ImageSection,
  ParcelInfoContainer,
  ParcelInfoKey,
  ParcelInfoValue,
  ShipmentLogo,
  Spinner,
  SpinnerSection,
  Title,
} from "./StorageItemPanel.styles";

interface StorageItemPanelProps {
  isOpen: boolean;
  isLoading: boolean;
  isLoadingHistoryShipment: boolean;
  isLoadingOutgoingShipment: boolean;
  createCustomsDeclaration: (data: CustomInformationDto) => Promise<void>;
  panelData: any;
  historyDetailedShipment: any;
  outgoingDetailedShipment: any;
  detailedShipment: any;
  units: string;
  openRightPanel: OpenRightPanel;
  getRequestTypes: () => Promise<void>;
  serviceRequestsTypes: any;
}

const StorageItemPanel: FC<StorageItemPanelProps> = React.memo(
  ({
    isOpen,
    isLoading: isLoadingProp,
    createCustomsDeclaration,
    getRequestTypes,
    serviceRequestsTypes,
    panelData,
    openRightPanel,
    detailedShipment,
    outgoingDetailedShipment,
    isLoadingOutgoingShipment,
    historyDetailedShipment,
    isLoadingHistoryShipment,
    units,
  }) => {
    const { i18n, t } = useTranslation("common");
    const locale = getFnsLocale(i18n.language);
    const navigate = useNavigate();
    const id = panelData.get("id");
    const shipment =
      detailedShipment.get(id) ||
      outgoingDetailedShipment.get(id) ||
      historyDetailedShipment.get(id, Map());

    const declarations = shipment.getIn(["items", 0, "customs_declarations"]);
    const readOnly = !!panelData.get("readOnly");
    const overrideAndEnablePackOptions = panelData.get(
      "overrideAndEnablePackOptions",
    );
    const isCartVariant = panelData.get("isCartVariant");
    const getShipment = panelData.get("getShipment");
    const updateComment = panelData.get("updateComment");
    const updateItemComment = panelData.get("updateItemComment");
    const showShipment = !!shipment.size;
    const isLoading =
      isLoadingProp || isLoadingOutgoingShipment || isLoadingHistoryShipment;
    const receivedAt = shipment.get("received_at") || new Date();
    const systemReceivedAt = Date.parse(receivedAt);
    const received = showShipment
      ? format(new Date(systemReceivedAt), "dd MMMM, y", {
          locale,
        })
      : "";
    const hasInventoryItems = panelData.get("hasInventoryItems");
    const preventImageZoom = panelData.get("preventImageZoom");

    // originalItem can either be parcel if coming from StorageList and in that case it doesn't have SKU prop loaded
    // if it has SKU loaded then it is inventory item if SKU starts with letter I
    const originalItem = panelData.get("originalItem", Map());
    const originalItemId = originalItem.get("id");
    const originalItemLatest =
      shipment &&
      shipment
        .get("items", List())
        .find((x: any) => x.get("id") === originalItemId);

    const isInventoryItem =
      originalItem.get("sku") && originalItem.get("sku").startsWith("I");
    let itemDetails = isInventoryItem
      ? shipment
          .get("items", List())
          .find((x: any) => x.get("id") === originalItem.get("id"))
      : shipment;
    if (!itemDetails) itemDetails = Map();

    useEffect(() => {
      if (!shipment.size && id) getShipment(id).catch(toastResponseError);
    }, [isOpen, getShipment, id, shipment.size]);

    useEffect(() => {
      if (shipment.size && !panelData.get("preventReLoading"))
        getShipment(id).catch(toastResponseError);

      if (!serviceRequestsTypes) {
        getRequestTypes().catch(toastResponseError);
      }
    }, [
      id,
      getRequestTypes,
      serviceRequestsTypes,
      getShipment,
      shipment.size,
      panelData,
    ]);

    const openServicePanel = useCallback(
      (requestType: string, isInventoryItemVariant?: boolean) => {
        openRightPanel(
          "SERVICE_REQUEST",
          panelData
            .set("shipment", shipment)
            .set("requests", shipment.get("service_requests"))
            .set("requestType", requestType)
            .set("groupRequests", null)
            .set("preventReLoading", true)
            .set("requestInventoryShipmentData", shipment)
            .set("readOnly", readOnly)
            .set("isInventoryItemVariant", isInventoryItemVariant)
            .set("transferInventoryItemId", originalItem.get("id")),
        );
      },
      [openRightPanel, panelData, shipment, readOnly],
    );

    const openGroupRequestsServicePanel = useCallback(
      (
        requestType: string,
        groupRequests: any,
        isInventoryItemVariant?: boolean,
      ) => {
        openRightPanel(
          "SERVICE_REQUEST",
          panelData
            .set("shipment", shipment)
            .set("requests", shipment.get("service_requests"))
            .set("requestType", requestType)
            .set("groupRequests", groupRequests)
            .set("preventReLoading", true)
            .set("requestInventoryShipmentData", shipment)
            .set("readOnly", readOnly)
            .set("isInventoryItemVariant", isInventoryItemVariant)
            .set("transferInventoryItemId", originalItem.get("id")),
        );
      },
      [openRightPanel, panelData, shipment, readOnly],
    );

    const openPackOptions = useCallback(
      () =>
        openRightPanel(
          "PACK_OPTIONS",
          panelData
            .set("preventReLoading", true)
            .set("returnScreen", "STORAGE_ITEM")
            .set("readOnly", readOnly)
            .set("overrideAndEnablePackOptions", overrideAndEnablePackOptions),
        ),
      [openRightPanel, panelData, readOnly, overrideAndEnablePackOptions],
    );

    const openInventoryPackOptions = useCallback(
      () =>
        openRightPanel(
          "PACK_OPTIONS",
          panelData
            .set("preventReLoading", true)
            .set("returnScreen", "STORAGE_ITEM")
            .set("readOnly", readOnly)
            .set("useInventoryAddons", true)
            .set("overrideAndEnablePackOptions", overrideAndEnablePackOptions),
        ),
      [openRightPanel, panelData, readOnly, overrideAndEnablePackOptions],
    );

    const openInventory = useCallback(
      () => navigate(`/parcels/inventory/${originalItem.get("id")}`),
      [originalItem, navigate],
    );

    const handleBack = useCallback(() => {
      openRightPanel(
        panelData.get("returnPreviousPanel"),
        panelData
          .set("id", panelData.get("consolidationId"))
          .set("returnPreviousPanel", panelData.get("rootReturnPanel"))
          .set("sku", panelData.get("consolidationSku")),
      );
    }, [openRightPanel, panelData]);

    const mainTitle = isInventoryItem
      ? itemDetails.get("description")
      : panelData.get("description");
    const pictures = itemDetails.get("pictures", List());
    const weight = itemDetails.get("weight") || "";

    return (
      <ContentWrapper key={id}>
        <Heading>
          {readOnly && (
            <BackButton color={ButtonColor.Black50} onClick={handleBack}>
              <LeftArrowIcon type={IconType.Arrow} />
              {panelData.get("consolidationSku")}:
            </BackButton>
          )}
          <ShipmentLogo
            $readOnly={readOnly}
            image={panelData.get("emblem_thumb_url") || ParcelIcon}
          />
          <Title $readOnly={readOnly}>{mainTitle}</Title>
        </Heading>
        <TabsView>
          <Tab title={t("parcels.details")}>
            <ContentContainer>
              <Container>
                {!pictures.size && isLoading && (
                  <SpinnerSection>
                    <Spinner isActive={isLoading} />
                  </SpinnerSection>
                )}
                {!!pictures.size && (
                  <ImageSection>
                    <ImageViewer2
                      images={pictures.toJS()}
                      preventImageZoom={preventImageZoom}
                    />
                  </ImageSection>
                )}
                <ParcelInfoContainer>
                  <div>
                    <ParcelInfoKey>{t("parcels.receivedDate")}: </ParcelInfoKey>
                    <ParcelInfoValue>{received}</ParcelInfoValue>
                  </div>
                  <div>
                    <ParcelInfoKey>{`${t("parcels.weight")}: `}</ParcelInfoKey>
                    <ParcelInfoValue>{`${weight} ${t(
                      `units.${units}.primaryShort`,
                    )}`}</ParcelInfoValue>
                  </div>
                </ParcelInfoContainer>
                {isInventoryItem && (
                  <>
                    <ParcelInfoContainer>
                      <div>
                        <ParcelInfoKey>{t("parcels.package")}: </ParcelInfoKey>
                        <ParcelInfoValue>
                          {shipment.get("description")}
                        </ParcelInfoValue>
                      </div>
                    </ParcelInfoContainer>
                    {!originalItemLatest && <InventoryItemCommentSkeleton />}
                    {originalItemLatest && (
                      <InventoryItemComment
                        shipment={originalItemLatest}
                        updateComment={updateItemComment}
                      />
                    )}
                    <ServicesButtons
                      shipment={shipment}
                      openPackOptions={openInventoryPackOptions}
                      openServicePanel={openServicePanel}
                      openGroupRequestsServicePanel={
                        openGroupRequestsServicePanel
                      }
                      isCartVariant={isCartVariant}
                      isInventoryItemVariant
                      isItemInCart={panelData.get("isItemInCart")}
                      originalItem={originalItem}
                    />
                  </>
                )}
                {!isInventoryItem && (
                  <>
                    {shipment.size <= 0 && <InventoryItemCommentSkeleton />}
                    {shipment.size > 0 && (
                      <ShipmentComment
                        shipment={shipment}
                        updateComment={updateComment}
                      />
                    )}
                    <ServicesButtons
                      shipment={shipment}
                      openPackOptions={openPackOptions}
                      openServicePanel={openServicePanel}
                      openGroupRequestsServicePanel={
                        openGroupRequestsServicePanel
                      }
                      openInventory={openInventory}
                      isCartVariant={isCartVariant}
                      hasInventoryItems={hasInventoryItems}
                      originalItem={originalItem}
                    />
                  </>
                )}
              </Container>
            </ContentContainer>
          </Tab>
          <Tab title={t("parcels.customsData")}>
            <Content>
              {isOpen && showShipment && (
                <CustomsDeclarationV2
                  itemId={id}
                  readOnly={readOnly}
                  declarations={declarations}
                  create={createCustomsDeclaration}
                  noPadding
                  isShipmentImportCustomsDeclaration
                  isShowHSCodeColumn
                />
              )}
            </Content>
          </Tab>
        </TabsView>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    isLoading: storageSelectDetailedLoading,
    isLoadingOutgoingShipment: outgoingSelectDetailedShipmentLoading,
    detailedShipment: storageSelectDetailedShipment,
    outgoingDetailedShipment: outgoingSelectDetailedShipment,
    serviceRequestsTypes: requestsSelectServiceTypes,
    addons: servicesSelectAddons,
    historyDetailedShipment: historySelectDetailedShipment,
    isLoadingHistoryShipment: historySelectDetailedShipmentLoading,
    units: authSelectUnits,
  }),
  {
    getRequestTypes,
    getAddonsMethods,
    getConsolidationAddonsMethods,
    createCustomsDeclaration,
    openRightPanel,
  },
);

export default withConnect(StorageItemPanel);
