import Button from "components/Button/Button";
import { RasterIcon } from "components/Icon/Icon";
import PreLoader from "components/PreLoader/PreLoader";
import styled from "styled-components";
import { PriceWrapper } from "styles/serviceRequestStyles";

export const Spinner = styled(PreLoader)`
  position: absolute;
  top: 100px;
`;

export const Price = styled(PriceWrapper)`
  border-bottom: none;
`;

export const SubmitButton = styled(Button)`
  margin-top: auto;
  align-self: center;
`;

export const TransferWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 8px;

  & > p {
    flex-shrink: 0;
  }
`;

export const TransferCodeWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
`;

export const CustomRasterIcon = styled(RasterIcon)`
  margin-right: 10px;
  height: 16px;
  width: 16px;
  background-color: #c8c9cc;
`;

interface PriceProps {
  $isHighlighted: boolean;
}

export const GroupItem = styled.span<PriceProps>`
  display: flex;
  padding: 20px;
  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.black : "#808080"};
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.lightgrey};
`;

export const GroupItemHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const GroupItemCost = styled.span<PriceProps>`
  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.black : "#808080"};
`;
