import React, { FC, useState } from "react";
import { Content } from "containers/RightPanel/RightPanel.styles";
import { List } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { CustomInformationDto } from "types/api/cart";
import useFetchOnceEffect from "../../../../../hooks/useFetchOnceEffect";
import {
  createCustomsDeclaration,
  importCustomInformation,
} from "../../../../../reduxFolder/reducers/cart";
import {
  cartSelectCart,
  cartSelectCustomInformation,
  cartSelectHasImportedCustomsData,
} from "../../../../../reduxFolder/selectors/cartSelectors";
import { panelSelectOpened } from "../../../../../reduxFolder/selectors/rightPanelSelectors";
import { USD } from "../../../../../styles/formStyles";
import { calculateCustomsDataTotal } from "../../../../../utils/customsDataHelpers";
import formatter from "../../../../../utils/formatter";
import {
  DeclarationTotal,
  StyledCustomsDeclarationV2,
  TabMain,
} from "../NotFinishedPanel.styles";
import { indexToTitleMapping } from "./helpers";

interface CustomsPanelProps {
  cart: any;
  customInformation: any;
  delivery: string;
  onActiveTabChange: (tab: string) => void;
  importCustomInformation: () => Promise<void>;
  createCustomsDeclaration: (data: CustomInformationDto) => Promise<void>;
  hasImportedCustomsData: boolean;
  isPanelOpened: boolean;
}

const CustomsPanel: FC<CustomsPanelProps> = React.memo(
  ({
    cart,
    importCustomInformation,
    createCustomsDeclaration,
    customInformation,
    onActiveTabChange,
    delivery,
    hasImportedCustomsData,
    isPanelOpened,
  }) => {
    const { t } = useTranslation("common");

    const id = cart.get("id");
    const declarations = customInformation ?? List();
    const isShowHSCodeColumn = delivery === "Berlin Post";

    useFetchOnceEffect(
      !customInformation.size && !hasImportedCustomsData && isPanelOpened,
      importCustomInformation,
    );

    const getTotal = (nameProp: string) =>
      declarations.reduce(
        (sum: number, information: any) => sum + information.get(nameProp),
        0,
      );

    const [headerData, setHeaderData] = useState({
      size: declarations.size || 0,
      total: formatter.currency(getTotal("value")),
    });

    const handleCustomsDataChange = (newValues: any[]) => {
      const [newTotal, newLength] = calculateCustomsDataTotal(newValues);
      setHeaderData({
        size: newLength,
        total: newTotal,
      });
    };

    const handleCustomsDeclarationSubmit = () => {
      onActiveTabChange(t(indexToTitleMapping[2]));
    };

    return (
      <TabMain>
        <Content>
          <DeclarationTotal>
            {`${t("shipping.total")}: ${headerData.size} ${t(
              "shipping.totalRows",
            )} - $${headerData.total} `}
            <USD>USD</USD>
          </DeclarationTotal>
          <StyledCustomsDeclarationV2
            itemId={id}
            declarations={declarations}
            create={createCustomsDeclaration}
            onCustomsDataChange={handleCustomsDataChange}
            noPadding
            isShowHSCodeColumn={isShowHSCodeColumn}
            isHSCodeValidationRequired
            isWizardStep
            onSubmit={handleCustomsDeclarationSubmit}
            saveButtonText={t("common.continue")}
          />
        </Content>
      </TabMain>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    cart: cartSelectCart,
    customInformation: cartSelectCustomInformation,
    hasImportedCustomsData: cartSelectHasImportedCustomsData,
    isPanelOpened: panelSelectOpened,
  }),
  {
    importCustomInformation,
    createCustomsDeclaration,
  },
);

export default withConnect(CustomsPanel);
