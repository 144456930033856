import React, { FC, useCallback } from "react";
import {
  Content,
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { ButtonColor, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import { useFormik } from "formik";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { ServiceRequestProps } from "types/StorageForms/ServiceRequest";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import CopyToClipboard from "../../../../components/CopyToClipboard/CopyToClipboard";
import Icon from "../../../../components/Icon/Icon";
import Input from "../../../../components/Input/Input";
import PackagesList from "../../../../components/PackagesList/PackagesList";
import packagesList from "../../../../components/PackagesList/PackagesList";
import PriceBlock from "../../../../components/PriceBlock/PriceBlock";
import { requestNames } from "../../../../reduxFolder/reducers/serviceRequests";
import {
  Comment,
  Form,
  Message,
  MessageIcon,
  MessageWrapper,
  Paragraph,
  Title,
} from "../../../../styles/serviceRequestStyles";
import { isTransferringState } from "../../../../utils/common";
import RequestInfo from "../RequestInfo/RequestInfo";
import {
  getRequestInfo,
  getRequestInventoryInfo,
  serviceRequestFormik,
  withConnect,
} from "./requestHelper";
import {
  GroupItem,
  GroupItemCost,
  GroupItemHeader,
  Price,
  Spinner,
  SubmitButton,
  TransferCodeWrapper,
  TransferWrapper,
} from "./ServiceRequest.styles";

const ServiceRequest: FC<ServiceRequestProps> = React.memo(
  ({
    isLoading,
    serviceTypes,
    panelData,
    openRightPanel,
    transferRequests,
    storageItems,
    selectedParcels,
    ...otherProps
  }) => {
    const { t } = useTranslation("common");

    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      setFieldValue,
      handleSubmit,
    } = useFormik(
      serviceRequestFormik({
        t,
        panelData,
        serviceTypes,
        isLoading,
        openRightPanel,
        storageItems,
        selectedParcels,
        ...otherProps,
      }),
    );

    const handleClose = useCallback(
      () => openRightPanel("STORAGE_ITEM", panelData),
      [panelData, openRightPanel],
    );
    const id = panelData.get("id");
    const readOnly = panelData.get("readOnly");
    const requestType = panelData.get("requestType");
    const isInventoryItemVariant = panelData.get("isInventoryItemVariant");
    const groupRequestsRaw = panelData.get("groupRequests");
    const groupRequests = !!groupRequestsRaw
      ? groupRequestsRaw.sortBy((x: any) => x.get("type"))
      : null;

    // Overrides
    const customTitle = !!groupRequests
      ? requestType
      : t(`serviceRequests.${requestType}.title`);

    const isRequestInventoryShipment =
      requestType === "requestInventoryShipment";
    const isRequestTransferShipment = requestType === "requestTransferShipment";
    const isRequestAdditionalPhotos = requestType === "additionalPhotos";
    let listOfTransferItems;

    // Conditional sections
    let showParagraph = true;
    const showSecondParagraph = isRequestInventoryShipment;
    let showDefaultMessageAndCommentSection =
      !isRequestInventoryShipment && !isRequestTransferShipment;
    let showComment = !isRequestInventoryShipment && !isRequestAdditionalPhotos;
    let showPriceSection = !isRequestTransferShipment;
    const activeTransferRequest = transferRequests?.[id];
    const isActiveTransferRequest =
      !!activeTransferRequest && activeTransferRequest.status === "requested";
    const isClientTransferring = isTransferringState(panelData.get("state"));

    let overrideRequestInfoMessage;
    let requestCost;
    let costSuffix = "";
    let requestInfo = Map();
    if (isRequestInventoryShipment) {
      requestInfo = getRequestInventoryInfo(
        panelData
          .get("requestInventoryShipmentData")
          .get("unpacking_task", Map()),
      );
    }
    if (!!groupRequests) {
      for (let i = 0; i < groupRequests.count(); i++) {
        const key = groupRequests.get(i)?.get("type");
        const activeRequest = getRequestInfo(
          panelData.get("requests"),
          key,
          true,
        );
        if (activeRequest && activeRequest.get("id")) {
          requestInfo = activeRequest;
          break;
        }
      }
    } else {
      requestInfo = getRequestInfo(panelData.get("requests"), requestType);
    }

    const hasRequest = requestInfo.get("hasRequest");

    const paragraphText =
      isRequestTransferShipment && isClientTransferring
        ? t(`serviceRequests.${requestType}.paragraphClientTransferring`)
        : t(`serviceRequests.${requestType}.paragraph`);

    const labelText =
      isRequestTransferShipment && isClientTransferring
        ? t("serviceRequests.requestTransferShipment.transferPartnerCode")
        : t("serviceRequests.requestTransferShipment.transferPartnerId");

    let submitTitle = requestInfo.get("isRequestCancelable")
      ? t("common.cancelRequest")
      : t(`serviceRequests.${requestType}.submit`);
    if (isActiveTransferRequest) {
      submitTitle = t(`serviceRequests.requestTransferShipment.processed`);
    }

    if (isRequestTransferShipment) {
      if (isInventoryItemVariant) {
        const transferInventoryItemId = panelData.get(
          "transferInventoryItemId",
        );
        const selectedInventoryItems = panelData.get("selectedInventoryItems");
        const shipment = panelData.get("shipment");

        if (selectedInventoryItems && selectedInventoryItems.length) {
          listOfTransferItems = shipment
            .get("items")
            .filter((x: any) => selectedInventoryItems.includes(x.get("id")));
        } else {
          listOfTransferItems = shipment
            .get("items")
            .filter((x: any) => transferInventoryItemId === x.get("id"));
        }
      } else {
        const bulkTransferIds = selectedParcels?.toJS();
        const ids = bulkTransferIds?.length ? bulkTransferIds : [id];
        listOfTransferItems = storageItems.filter((x) =>
          ids.includes(x.get("id")),
        );
      }
    } else if (isRequestInventoryShipment) {
      requestCost = t(`serviceRequests.${requestType}.cost`);
      costSuffix = t(`serviceRequests.${requestType}.costSuffix`);
    } else if (!!groupRequests) {
      showPriceSection = false;
      showDefaultMessageAndCommentSection = false;
      showParagraph = false;
      overrideRequestInfoMessage = t(`serviceRequests.unprocessed`);
      submitTitle = hasRequest
        ? t(`common.cancelRequest`)
        : t(`serviceRequests.submit`);
      showComment = groupRequests.some(
        (x: any) => x.get("has_comment") === true,
      );
    } else {
      requestCost = serviceTypes
        .find(
          (request: any) =>
            requestNames[request.get("type") as keyof typeof requestNames] ===
            requestType,
        )
        .get("cost");
    }

    const handleSubmitButtonClick = () => {
      if (isActiveTransferRequest) return;
      handleSubmit();
    };

    const handleGroupCheckBoxClick = (groupRequest: any) => {
      setFieldValue("selectedGroupOption", groupRequest.get("type"));
    };

    return (
      <ContentWrapper>
        <Spinner isActive={isLoading} />
        <Heading $isBordered>
          {!(isRequestTransferShipment && activeTransferRequest) && (
            <button onClick={handleClose}>
              <Icon type={IconType.Arrow} />
            </button>
          )}
          <Title>{customTitle}</Title>
        </Heading>
        {isLoading || (
          <Content>
            <Form onSubmit={handleSubmit}>
              {hasRequest ? (
                <RequestInfo
                  request={requestInfo}
                  overrideMessage={overrideRequestInfoMessage}
                />
              ) : (
                <div>
                  {showParagraph && <Paragraph>{paragraphText}</Paragraph>}
                  {showSecondParagraph && (
                    <Paragraph style={{ marginTop: "-15px" }}>
                      {t(`serviceRequests.${requestType}.paragraph2`)}
                    </Paragraph>
                  )}
                  {showDefaultMessageAndCommentSection && (
                    <>
                      <MessageWrapper>
                        <MessageIcon type={requestType} />
                        <Message>
                          {t(`serviceRequests.${requestType}.message`)}
                        </Message>
                      </MessageWrapper>
                      {showComment && (
                        <Comment
                          name="comment"
                          placeholder={t("serviceRequests.placeholderComment")}
                          value={values.comment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          tooltip={errors.comment}
                          invalid={!!errors.comment && !!touched.comment}
                          disabled={readOnly}
                        />
                      )}
                    </>
                  )}
                  {showPriceSection && (
                    <Price>
                      <PriceBlock
                        price={requestCost}
                        title={t(`serviceRequests.requestCost`)}
                        suffix={costSuffix}
                      />
                    </Price>
                  )}
                  {isRequestTransferShipment && (
                    <>
                      <TransferWrapper>
                        <p>{labelText}</p>
                        <Input
                          {...(!isClientTransferring && { type: "number" })}
                          name="partnerTransferId"
                          value={values.partnerTransferId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          tooltip={errors.partnerTransferId}
                          invalid={!!errors.partnerTransferId}
                        />
                      </TransferWrapper>
                      {!activeTransferRequest && (
                        <PackagesList
                          extended
                          items={listOfTransferItems}
                          isReadOnly
                        />
                      )}
                      {activeTransferRequest && (
                        <TransferCodeWrapper>
                          <div>
                            {t(
                              "serviceRequests.requestTransferShipment.transferPartnerCode",
                            )}
                          </div>
                          <CopyToClipboard
                            text={activeTransferRequest.transferCode}
                          >
                            <h2>{activeTransferRequest.transferCode}</h2>
                          </CopyToClipboard>
                        </TransferCodeWrapper>
                      )}
                    </>
                  )}
                  {groupRequests && (
                    <>
                      {showComment && (
                        <Comment
                          name="comment"
                          value={values.comment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          tooltip={errors.comment}
                          invalid={!!errors.comment && !!touched.comment}
                          disabled={readOnly}
                        />
                      )}
                      {groupRequests.map((req: any) => (
                        <GroupItem $isHighlighted={true}>
                          <GroupItemHeader>
                            <Checkbox
                              checked={
                                values.selectedGroupOption === req.get("type")
                              }
                              onChange={() => handleGroupCheckBoxClick(req)}
                            />
                            {req.get("typeDisplayName")}
                          </GroupItemHeader>
                          <GroupItemCost $isHighlighted={true}>
                            {`$${parseFloat(req.get("cost")).toFixed(2)}`}
                          </GroupItemCost>
                        </GroupItem>
                      ))}
                    </>
                  )}
                </div>
              )}
              {!readOnly && (
                <SubmitButton
                  onClick={handleSubmitButtonClick}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  hidden={!!requestInfo.get("isSubmitHidden")}
                  color={
                    requestInfo.get("isRequestCancelable")
                      ? ButtonColor.Red
                      : isActiveTransferRequest
                        ? ButtonColor.Secondary
                        : ButtonColor.Primary
                  }
                  variant={ButtonVariant.Filled}
                >
                  {submitTitle}
                </SubmitButton>
              )}
            </Form>
          </Content>
        )}
      </ContentWrapper>
    );
  },
);

export default withConnect(ServiceRequest);
