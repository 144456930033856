import React, { FC, useRef } from "react";
import CustomsDeclarationV2 from "components/CustomDeclaration/CustomsDeclarationV2";
import {
  Content,
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { ButtonColor, ButtonType, ButtonVariant } from "enums/Button";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ExpectedItemsPanelProps } from "types/ExpectedForms/ExpectedItemsPanel";
import { CustomSenderErrorMsg } from "../../../../components/Addresses/components/AddressForm.styles";
import Button from "../../../../components/Button/Button";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import useFetchOnceEffect from "../../../../hooks/useFetchOnceEffect";
import {
  closeRightPanel,
  openRightPanel,
} from "../../../../reduxFolder/reducers/rightPanel";
import {
  createCustomsDeclarationForExpectedItem,
  createExpectedItem,
  deleteCustomsDeclarationForExpectedItem,
  deleteExpectedItem,
  getCustomsDeclarationsForExpectedItem,
  importCustomsDeclaration,
  setSelectedExpectedItemId,
  updateCustomsDeclarationForExpectedItem,
  updateExpectedItem,
} from "../../../../reduxFolder/reducers/storage";
import {
  selectedExpectedItemCustomsDeclarations,
  storageSelectExpectedShops,
} from "../../../../reduxFolder/selectors/storageSelectors";
import { capitalized } from "../../../../utils/common";
import Tab from "../../../RightPanel/components/Tab";
import TabsView from "../../../RightPanel/components/TabsView";
import {
  ButtonsWrapper,
  Container,
  ContentContainer,
  FormWrapper,
  Spinner,
  Title,
} from "./ExpectedItemPanel.styles";
import { expectedItemsFormik } from "./expectedItemsFormHelper";
import ExpectedParcelForm from "./ExpectedParcelForm";

const ExpectedItemPanel: FC<ExpectedItemsPanelProps> = React.memo(
  ({
    panelData,
    openRightPanel,
    closeRightPanel,
    shops,
    deleteExpectedItem,
    getCustomsDeclarationsForExpectedItem,
    createCustomsDeclarationForExpectedItem,
    updateCustomsDeclarationForExpectedItem,
    deleteCustomsDeclarationForExpectedItem,
    selectedExpectedItemCustomsDeclarations,
    createExpectedItem,
    updateExpectedItem,
    setSelectedExpectedItemId,
  }) => {
    const { t } = useTranslation("common");
    const formProps = useFormik(
      expectedItemsFormik(
        t,
        panelData,
        shops,
        createExpectedItem,
        updateExpectedItem,
        openRightPanel,
        setSelectedExpectedItemId,
      ),
    );

    const { handleSubmit, isSubmitting, values } = formProps;

    const isEditForm = panelData.get("isEditForm");
    const isCustomsDataTab = panelData.get("isCustomsDataTab");
    const formRef = useRef(null);

    const handleDelete = () => {
      deleteExpectedItem(values.id).then(() => {
        closeRightPanel();
      });
    };

    useFetchOnceEffect(isEditForm, function () {
      return getCustomsDeclarationsForExpectedItem(values.id);
    });

    return (
      <ContentWrapper>
        <Heading>
          <Title>{`${
            isEditForm ? t("common.edit") : capitalized(t("common.new"))
          } Expected parcel`}</Title>
        </Heading>
        <TabsView
          openTab={
            isCustomsDataTab ? t("parcels.customsData") : t("parcels.details")
          }
        >
          <Tab title={t("parcels.details")}>
            <ContentContainer>
              <Container>
                <Spinner isActive={false} />
                <FormWrapper ref={formRef} onSubmit={handleSubmit}>
                  <Content>
                    <ExpectedParcelForm {...formProps} shops={shops} />
                  </Content>
                  <ButtonsWrapper>
                    {isEditForm && (
                      <Button
                        color={ButtonColor.Red}
                        onClick={() => handleDelete()}
                      >
                        {t("common.remove")}
                      </Button>
                    )}
                    <Button
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      type={ButtonType.Submit}
                      color={ButtonColor.Primary}
                      variant={ButtonVariant.Filled}
                    >
                      {t("common.saveChanges")}
                    </Button>
                  </ButtonsWrapper>
                </FormWrapper>
              </Container>
            </ContentContainer>
          </Tab>
          <Tab
            title={t("parcels.customsData")}
            tabHeader={
              isEditForm ? (
                t("parcels.customsData")
              ) : (
                <Tooltip
                  body={
                    <CustomSenderErrorMsg>
                      {t("parcels.notFinishedShowRequiredFieldsError")}
                    </CustomSenderErrorMsg>
                  }
                >
                  {t("parcels.customsData")}
                </Tooltip>
              )
            }
            disabled={!isEditForm}
          >
            <Content>
              <CustomsDeclarationV2
                itemId={values.id}
                declarations={selectedExpectedItemCustomsDeclarations}
                create={createCustomsDeclarationForExpectedItem}
                update={updateCustomsDeclarationForExpectedItem}
                remove={deleteCustomsDeclarationForExpectedItem}
                noPadding
                isShipmentImportCustomsDeclaration
                hideFileImport
                useOldCustomsLogic
              />
            </Content>
          </Tab>
        </TabsView>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    shops: storageSelectExpectedShops,
    selectedExpectedItemCustomsDeclarations:
      selectedExpectedItemCustomsDeclarations,
  }),
  {
    openRightPanel,
    closeRightPanel,
    createExpectedItem,
    updateExpectedItem,
    deleteExpectedItem,
    getCustomsDeclarationsForExpectedItem,
    createCustomsDeclarationForExpectedItem,
    updateCustomsDeclarationForExpectedItem,
    deleteCustomsDeclarationForExpectedItem,
    importCustomsDeclaration,
    setSelectedExpectedItemId,
  },
);

export default withConnect(ExpectedItemPanel);
