import { List, Map } from 'immutable';
import { createSelector, lruMemoize } from 'reselect';
import { initialState } from '../reducers/cart';
import { peek, peekOr, selector } from '../selectorHelpers';

export const selectCart = (state) => state.get('cart', initialState);

export const cartSelectCart = selector(selectCart, peek('cart'));
export const cartSelectCartId = selector(cartSelectCart, peek('id'));
export const cartSelectCartItems = selector(
  cartSelectCart,
  peekOr(['items'], List()),
);
export const cartSelectCartConsolidationItems = selector(
  cartSelectCart,
  peekOr(['addons_codes'], List()),
);
export const cartSelectIsEmpty = selector(
  cartSelectCartItems,
  lruMemoize((items) => !items.size),
);
export const cartSelectCartDeliveryMethod = selector(
  cartSelectCart,
  peek('preferred_carrier'),
);
export const cartSelectDestination = selector(
  cartSelectCart,
  peek('destination_address'),
);
export const cartSelectDestinationId = selector(
  cartSelectCart,
  peek('destination_address_id'),
);
export const cartSelectCartPacking = selector(
  cartSelectCart,
  peekOr(['optional_line_item_codes'], List()),
);
export const cartSelectCustomInformation = selector(
  cartSelectCart,
  peekOr(['customs_informations'], List()),
);
export const cartSelectHasImportedCustomsData = selector(
  cartSelectCart,
  peek('hasImportedCustomsData'),
);
export const cartSelectEstimate = selector(
  cartSelectCart,
  peekOr(['estimate'], Map()),
);
export const customDeclarationsDescriptions = selector(
  selectCart,
  peek('hsCodes'),
);
export const cartSelectSku = selector(cartSelectCart, peek('sku'));
export const cartSelectState = selector(cartSelectCart, peek('state'));
export const cartSelectLoadingInsurance = selector(
  selectCart,
  peek('isLoadingUpdateInsurance'),
);
export const cartSelectLoading = selector(selectCart, peek('isLoading'));
export const cartSelectForOutgoing = createSelector(
  [
    cartSelectSku,
    cartSelectCartItems,
    cartSelectCartId,
    cartSelectCartDeliveryMethod,
    cartSelectState,
  ],
  lruMemoize((sku, items, id, delivery, state) => {
    const weight = items
      .reduce((sum, item) => sum + item.getIn(['received_item', 'weight']), 0)
      .toFixed(2);
    const cart = {};

    if (items.size)
      cart.items = List([
        Map({
          id,
          sku,
          items,
          weight,
          delivery,
          state,
        }),
      ]);

    return Map(cart);
  }),
);
