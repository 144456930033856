import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { ButtonColor, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import { List, Map } from "immutable/dist/immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  AddonsMethodsResponseDto,
  ConsolidationAddonsMethodsResponseDto,
  PackingMethodsResponseDto,
} from "types/api/cart";
import { ShipmentsUpdateCommentDto } from "types/api/shipments";
import { OpenRightPanel } from "types/common/rightPanel";
import Icon, { IconPng } from "../../../../components/Icon/Icon";
import ImageViewer2 from "../../../../components/ImageViewer2/ImageViewer2";
import PackageSizes from "../../../../components/PackageSizes/PackageSizes";
import PackagesList from "../../../../components/PackagesList/PackagesList";
import PackagesPagination from "../../../../components/PackagesPagination/PackagesPagination";
import ServiceLogo from "../../../../components/ServiceLogo/ServiceLogo";
import useFetchOnceEffect from "../../../../hooks/useFetchOnceEffect";
import {
  createCustomsDeclaration,
  getDetailedShipment,
  updateComment,
} from "../../../../reduxFolder/reducers/outgoing";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  getAddonsMethods,
  getConsolidationAddonsMethods,
  getPackingMethods,
} from "../../../../reduxFolder/reducers/services";
import { authSelectUnits } from "../../../../reduxFolder/selectors/globalSelectors";
import {
  outgoingSelectDetailedItems,
  outgoingSelectDetailedLoading,
} from "../../../../reduxFolder/selectors/outgoingSelectors";
import { panelSelectData } from "../../../../reduxFolder/selectors/rightPanelSelectors";
import {
  servicesSelectAddons,
  servicesSelectConsolidationAddons,
  servicesSelectPackingMethods,
} from "../../../../reduxFolder/selectors/servicesSelectors";
import {
  ExcessMarker,
  HeaderTitle,
  InnerSection,
  PayButton,
  Section,
  SectionContent,
  SectionHeader,
} from "../../../../styles/outgoingStyles";
import { ChangeButton } from "../../../../styles/parcelsStyles";
import { theme } from "../../../../styles/styles";
import formatter from "../../../../utils/formatter";
import { getAddonsIconsImage } from "../../../Delivery/deliveryHelper";
import {
  CancelButton,
  CancelIcon,
  ContentWrapper,
  Footer,
  ImageSection,
  PanelAddressItem,
  SKUTitle,
} from "./ParcelInfoTab.styles";

interface ParcelInfoTabProps {
  id?: string | number;
  panelData: any;
  packingMethods: any;
  detailedItem: any;
  addons: any;
  consolidationAddons: any;
  units: string;
  openRightPanel: OpenRightPanel;
  getAddonsMethods: () => Promise<AddonsMethodsResponseDto[]>;
  getPackingMethods: () => Promise<PackingMethodsResponseDto[]>;
  getConsolidationAddonsMethods: () => Promise<
    ConsolidationAddonsMethodsResponseDto[]
  >;
  onPackingCancel?: () => void;
  getDetailedShipment: (id: number) => Promise<void>;
  updateComment: (id: number, data: ShipmentsUpdateCommentDto) => Promise<void>;
}

const ParcelInfoTab: FC<ParcelInfoTabProps> = React.memo(
  ({
    id,
    packingMethods,
    onPackingCancel,
    detailedItem,
    addons,
    getPackingMethods,
    getAddonsMethods,
    consolidationAddons,
    getConsolidationAddonsMethods,
    panelData,
    openRightPanel,
    updateComment,
    getDetailedShipment,
    units,
  }) => {
    const { t } = useTranslation("common");
    const contentRef = useRef<HTMLDivElement>(null);
    const [selectedPackageIndex, setSelectedPackageIndex] = useState(0);
    const detailed = detailedItem.get(id, Map());
    const delivery = detailed.get("preferred_carrier");
    const state = detailed.get("state");
    const packingCode = detailed.getIn(["optional_line_item_codes", 0]);
    const isPaid = state === "paid";
    const disabledChange =
      ["paid", "claimed_for_packing", "picked", "claimed"].indexOf(state) !==
      -1;
    const disabledChaneForPackingGrade = disabledChange || state === "packed";
    const disabledChangeForShippingMethod =
      disabledChange || state !== "pending";
    const hideFooterAction =
      ["claimed_for_packing", "picked", "claimed"].indexOf(state) !== -1;
    const showPackedParcelInformation =
      ["paid", "packed"].indexOf(state) !== -1;
    const showAllPackagesList = !showPackedParcelInformation;
    const showPayButton = state !== "pending" && !isPaid;

    const packing = Map(
      packingMethods.find(
        (packingMethod: any) => packingMethod.get("code") === packingCode,
      ),
    );
    const packingTitle = packing ? (packing.get("title") as string) : "";
    const services = !!packingMethods.size;
    const foundPackageId = panelData.get("foundPackageId", 0);
    const excessItemsOriginal = panelData.get("excessItems");
    const excessItems =
      !!excessItemsOriginal &&
      excessItemsOriginal.map((item: any) => item.get("item"));
    const isHideFooter = hideFooterAction;

    const packages = detailed.get("packages", List());
    const hasFewPackages = packages.count() > 1;
    const items = detailed.get("items");

    useFetchOnceEffect(!packingMethods.size, getPackingMethods);
    useFetchOnceEffect(!addons.size, getAddonsMethods);
    useFetchOnceEffect(
      !consolidationAddons.size,
      getConsolidationAddonsMethods,
    );

    useEffect(() => {
      if (contentRef.current && foundPackageId)
        contentRef.current.scrollIntoView();
    }, [contentRef.current, foundPackageId]);

    const clickChangingAddress = useCallback(() => {
      openRightPanel(
        "CHANGING_ADDRESS",
        panelData.set("addressId", detailed.get("destination_address_id")),
      );
    }, [openRightPanel, panelData, detailed]);
    const editDeliveryMethod = useCallback(
      () =>
        openRightPanel(
          "DELIVERY_METHODS",
          panelData
            .set("deliveryCountry", detailed.get("country"))
            .set("deliveryMethod", delivery),
        ),
      [openRightPanel, panelData, detailed, delivery],
    );
    const editPackingGrade = useCallback(
      () =>
        openRightPanel(
          "PACKING_GRADE",
          panelData
            // .set('id', id) FIXME:
            .set("consolidationSku", detailed.get("sku"))
            .set("deliveryMethod", delivery)
            .set("packingCode", packingCode)
            .set("packingMethods", packingMethods)
            .set(
              "selectedConsolidationAddons",
              detailed.get("consolidation_addons"),
            ),
        ),
      [
        openRightPanel,
        panelData,
        detailed,
        delivery,
        packingCode,
        packingMethods,
      ],
    );
    const clickPackagesItem = (item: any) => {
      openRightPanel(
        "STORAGE_ITEM",
        Map({
          readOnly: true,
          id: item.get("shipment_id"),
          originalItem: item,
          detailedItemID: id,
          consolidationSku: detailed.get("sku"),
          emblem_thumb_url: item.get("emblem_thumb_url"),
          description: item.get("shipment_tracking_number"),
          returnPreviousPanel: panelData.get("returnPreviousPanel"),
          excessItems: panelData.get("excessItems"),
          rootReturnPanel: panelData.get("returnPreviousPanel"),
          getShipment: getDetailedShipment,
          updateComment,
          isItemInCart: true,
        }),
      );
    };

    const handleSetSelectPackageIndex = useCallback(
      (index: number) => {
        setSelectedPackageIndex(index);
      },
      [setSelectedPackageIndex],
    );

    const clickReviewAndPay = useCallback(() => {
      openRightPanel("CONFIRM_PAYMENT_DETAILS", panelData);
    }, [openRightPanel, panelData]);

    const clickAlreadyPayed = useCallback(() => {
      openRightPanel("REVIEW_AND_PAY", panelData);
    }, [openRightPanel, panelData]);

    const rendererFooter = () => {
      if (isHideFooter) return null;

      const payButtonTitle = onPackingCancel
        ? t("common.reviewAndPrepay")
        : t("common.reviewAndPay");

      return (
        <Footer>
          {onPackingCancel && (
            <CancelButton onClick={onPackingCancel} color={ButtonColor.Red}>
              <CancelIcon type={IconType.Close} />
              {t("common.cancel")}
            </CancelButton>
          )}
          {showPayButton && (
            <PayButton
              onClick={clickReviewAndPay}
              variant={ButtonVariant.Filled}
              color={ButtonColor.Secondary}
            >
              {payButtonTitle}
            </PayButton>
          )}
          {isPaid && (
            <PayButton
              onClick={clickAlreadyPayed}
              variant={ButtonVariant.Filled}
              color={ButtonColor.Secondary}
            >
              {`${t("parcels.paymentPaid")} ($${formatter.currency(
                detailed.getIn(["estimate", "total"]),
              )})`}
            </PayButton>
          )}
        </Footer>
      );
    };

    const renderItemsList = (items: any, isExcessItems = false) => {
      const itemsCount = (!!items && items.count()) || 0;
      const localizedTitle = isExcessItems
        ? t("parcels.excessPackagesIncluded")
        : t("parcels.packagesIncluded");
      const trackNumberColor = isExcessItems ? theme.red : theme.black;
      return itemsCount === 0 ? null : (
        <Section ref={contentRef}>
          <SectionHeader>
            <HeaderTitle>
              <Icon type={IconType.Package} />
              {`${localizedTitle} (${itemsCount}):`}
              {isExcessItems ? <ExcessMarker /> : null}
            </HeaderTitle>
          </SectionHeader>
          <InnerSection>
            <SectionContent>
              <PackagesList
                extended
                trackNumberColor={trackNumberColor}
                items={items}
                foundItemId={foundPackageId}
                handleClick={clickPackagesItem}
                serviceRequestPanelData={{
                  detailedItemID: id,
                  consolidationSku: detailed.get("sku"),
                  returnPreviousPanel: panelData.get("returnPreviousPanel"),
                  getShipment: getDetailedShipment,
                  readOnly: true,
                }}
              />
            </SectionContent>
          </InnerSection>
        </Section>
      );
    };

    const renderPackageInfo = (pkg: any, showMPSInfo = false) => {
      const pictures = pkg.get("pictures", List());
      const items = pkg.get("items");
      const sku = pkg.get("sku");

      const weight = pkg.get("weight");
      const height = pkg.get("height");
      const width = pkg.get("width");
      const depth = pkg.get("depth");

      const itemsUnitsTitle =
        items.count() === 1 ? t("parcels.item") : t("parcels.items");
      const itemsTitle = `${items.count()} ${itemsUnitsTitle}`;
      const weightTitle = `${formatter.weight(weight)} ${t(
        `units.${units}.primaryShort`,
      )}`;
      const itemsPerWeightTitle = `${itemsTitle} / ${weightTitle}`;

      const infoSection = (
        <>
          <SectionHeader>
            <SKUTitle>{sku}</SKUTitle>
          </SectionHeader>
          <SectionContent>{itemsPerWeightTitle}</SectionContent>
          <SectionContent>
            <PackageSizes
              width={width}
              height={height}
              depth={depth}
              units={units}
            />
          </SectionContent>
        </>
      );

      return (
        <>
          {(showMPSInfo || !!pictures.size) && (
            <InnerSection>
              {showMPSInfo ? infoSection : null}
              <SectionContent>
                {!!pictures.size && (
                  <ImageSection>
                    <ImageViewer2 images={pictures.toJS()} />
                  </ImageSection>
                )}
              </SectionContent>
            </InnerSection>
          )}
          {renderItemsList(excessItems, true)}
          {renderItemsList(items)}
        </>
      );
    };

    return (
      services && (
        <>
          <ContentWrapper isHideFooter={isHideFooter}>
            <Section>
              <SectionHeader>
                <HeaderTitle>
                  <Icon type={IconType.Address} />{" "}
                  {t("parcels.shippingAddress")}:
                </HeaderTitle>
                <ChangeButton
                  color={ButtonColor.Primary}
                  hidden={disabledChange}
                  onClick={clickChangingAddress}
                >
                  {t("common.change")}
                </ChangeButton>
              </SectionHeader>
              <PanelAddressItem address={detailed} isHideButtons />
            </Section>
            <Section>
              <SectionHeader>
                <HeaderTitle>
                  <IconPng type="turbine" /> {t("parcels.shippingMethod")}:
                </HeaderTitle>
                <ChangeButton
                  color={ButtonColor.Primary}
                  hidden={disabledChangeForShippingMethod}
                  onClick={editDeliveryMethod}
                >
                  {t("common.change")}
                </ChangeButton>
              </SectionHeader>
              <InnerSection>
                <SectionContent>
                  <ServiceLogo serviceKey={delivery} />
                  {delivery}
                </SectionContent>
              </InnerSection>
            </Section>
            <Section>
              <SectionHeader>
                <HeaderTitle>
                  <IconPng type="tape" /> {t("parcels.packingGrade")}:
                </HeaderTitle>
                <ChangeButton
                  color={ButtonColor.Primary}
                  hidden={disabledChaneForPackingGrade}
                  onClick={editPackingGrade}
                >
                  {t("common.change")}
                </ChangeButton>
              </SectionHeader>
              <InnerSection>
                <SectionContent>
                  <ServiceLogo serviceKey={packingCode} />
                  {packingTitle}
                  {getAddonsIconsImage(
                    detailed.get("consolidation_addons"),
                    consolidationAddons,
                  )}
                </SectionContent>
              </InnerSection>
            </Section>
            {showPackedParcelInformation ? (
              <Section>
                <HeaderTitle>
                  <Icon type={IconType.Package} />
                  {hasFewPackages
                    ? t("parcels.parcelsInformation")
                    : t("parcels.parcelInformation")}
                  :
                </HeaderTitle>
                {hasFewPackages && (
                  <InnerSection>
                    <PackagesPagination
                      count={packages.count()}
                      selectedIndex={selectedPackageIndex}
                      onSelect={handleSetSelectPackageIndex}
                    />
                  </InnerSection>
                )}
              </Section>
            ) : null}
            {showPackedParcelInformation &&
              renderPackageInfo(
                packages.get(selectedPackageIndex),
                hasFewPackages,
              )}
            {showAllPackagesList && renderItemsList(excessItems, true)}
            {showAllPackagesList && renderItemsList(items)}
          </ContentWrapper>
          {rendererFooter()}
        </>
      )
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    detailedItem: outgoingSelectDetailedItems,
    packingMethods: servicesSelectPackingMethods,
    isLoadingDetailedItem: outgoingSelectDetailedLoading,
    addons: servicesSelectAddons,
    panelData: panelSelectData,
    units: authSelectUnits,
    consolidationAddons: servicesSelectConsolidationAddons,
  }),
  {
    getPackingMethods,
    createCustomsDeclaration,
    getAddonsMethods,
    getConsolidationAddonsMethods,
    openRightPanel,
    getDetailedShipment,
    updateComment,
  },
);

export default withConnect(ParcelInfoTab);
