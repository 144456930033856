import { fromJS, List, Map, updateIn } from 'immutable';
import { combineActions, createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';
import { normalizeImages } from './utils/helpers';

export const initialState = fromJS({
  cart: Map(),
  isLoading: false,
  isLoadingHsCodes: false,
  hasImportedCustomsData: false,
  hsCodes: [],
});

// const
export const GET_CART = 'cart/GET_CART';
export const DROP_CART = 'cart/DROP_CART';
export const DELETE_CART = 'cart/DELETE_CART';
export const ADD_PARCELS = 'cart/ADD_PARCELS';
export const REMOVE_PARCELS = 'cart/REMOVE_PARCELS';
export const SET_ADDRESS = 'cart/SET_ADDRESS';
export const SET_DELIVERY_METHOD = 'cart/SET_DELIVERY_METHOD';
export const SET_PACKING_METHODS = 'cart/SET_PACKING_METHODS';
export const SET_ADDONS_METHODS = 'cart/SET_ADDONS_METHODS';
export const DELETE_ADDONS_METHODS = 'cart/DELETE_ADDONS_METHODS';
export const SET_CONSOLIDATION_ADDONS_METHODS =
  'cart/SET_CONSOLIDATION_ADDONS_METHODS';
export const IMPORT_CUSTOM_INFORMATION = 'cart/IMPORT_CUSTOM_INFORMATION';
export const CREATE_CUSTOM_DECLARATION = 'cart/CREATE_CUSTOM_DECLARATION';
export const CONSOLIDATE_CART = 'cart/CONSOLIDATE_CART';
export const IMPORT_CUSTOMS_DECLARATION = 'cart/IMPORT_CUSTOMS_DECLARATION';
export const SET_PARTNER_ID = 'cart/SET_PARTNER_ID';
export const GET_DESCRIPTIONS = 'customDeclarationDescriptions/GET_DESCRIPTION';

// action creators
export const getCart = createAction(GET_CART, api.cart.getCart);
export const deleteCart = createAction(DELETE_CART, api.cart.deleteCart);
export const addParcels = createAction(`${ADD_PARCELS}_REQUEST`);
export const removeCartParcels = createAction(REMOVE_PARCELS);
export const addParcelsSuccess = createAction(`${ADD_PARCELS}_SUCCESS`);
export const addParcelsError = createAction(`${ADD_PARCELS}_ERROR`);
export const setAddress = createAction(SET_ADDRESS, api.cart.setAddress);
export const setDeliveryMethod = createAction(
  SET_DELIVERY_METHOD,
  api.cart.setDeliveryMethod,
);
export const setPackingMethods = createAction(
  SET_PACKING_METHODS,
  api.cart.setPackingMethods,
);
export const setAddonsMethods = createAction(
  SET_ADDONS_METHODS,
  api.cart.setAddonsMethods,
);
export const deleteAddonsMethods = createAction(
  DELETE_ADDONS_METHODS,
  api.cart.deleteAddonsMethods,
);

export const setConsolidationAddonsMethods = createAction(
  SET_CONSOLIDATION_ADDONS_METHODS,
  api.cart.setConsolidationAddonsMethods,
);
export const getDescriptionsByHsCodes = createAction(
  GET_DESCRIPTIONS,
  api.cart.getDescriptions,
);

export const importCustomInformation = createAction(
  IMPORT_CUSTOM_INFORMATION,
  api.cart.importCustomInformation,
);
export const createCustomsDeclaration = createAction(
  CREATE_CUSTOM_DECLARATION,
  ({ data }) => ({
    promise: api.cart.createCustomsDeclaration(data),
  }),
);
export const importCustomsDeclaration = createAction(
  IMPORT_CUSTOMS_DECLARATION,
  ({ id, file }) => {
    const data = new FormData();
    data.append('customs_declaration_import[file]', file);
    return {
      promise: api.cart.importCustomsDeclaration({ id, data }),
    };
  },
);
export const consolidateCart = createAction(
  CONSOLIDATE_CART,
  api.cart.consolidateCart,
);

export const dropCart = createAction(DROP_CART);

export const setPartnerId = createAction(SET_PARTNER_ID, (partnerId) => ({
  promise: api.cart.setPartnerId({ post_most_id: partnerId }),
}));

export const normalizeCart = (cart) =>
  cart.updateIn(['items'], (items) =>
    items.map((item) =>
      item.set('pictures', normalizeImages(item.get('pictures', List()))),
    ),
  );

// reducer
const cartReducer = handleActions(
  {
    [combineActions(`${GET_CART}_SUCCESS`, `${ADD_PARCELS}_SUCCESS`)]: (
      state,
      { payload },
    ) =>
      state
        .set('cart', normalizeCart(payload.get('data')))
        .set('isLoading', false),

    [REMOVE_PARCELS]: (state, { payload }) => {
      return state.updateIn(['cart', 'items'], (items) =>
        items.filter((x) => x.get('id') !== payload),
      );
    },

    [`${DELETE_CART}_REQUEST`]: (state) =>
      state.set('cart', Map()).set('isLoading', true),

    [`${SET_ADDRESS}_SUCCESS`]: (state, { payload }) => {
      const address = payload.get('data');
      return state
        .setIn(['cart', 'destination_address_id'], address.get('id'))
        .setIn(['cart', 'destination_address'], address)
        .set('isLoading', false);
    },

    [`${GET_DESCRIPTIONS}_REQUEST`]: (state) =>
      state.set('isLoadingHsCodes', true),

    [`${GET_DESCRIPTIONS}_SUCCESS`]: (state, { payload }) =>
      state.set('hsCodes', payload.get('data')).set('isLoadingHsCodes', false),

    [`${GET_DESCRIPTIONS}_ERROR`]: (state) =>
      state.set('isDescriptionLoading', false),

    [combineActions(
      `${DELETE_ADDONS_METHODS}_SUCCESS`,
      `${SET_DELIVERY_METHOD}_SUCCESS`,
      `${SET_PACKING_METHODS}_SUCCESS`,
      `${IMPORT_CUSTOM_INFORMATION}_SUCCESS`,
    )]: (state, { payload }) =>
      state
        .mergeIn(['cart'], payload.get('data'))
        .set('isLoading', false)
        .set('hasImportedCustomsData', true),

    [`${SET_ADDONS_METHODS}_SUCCESS`]: (state, { payload }) => {
      return state
        .setIn(['cart', 'items'], payload.get('data').get('items'))
        .set('isLoading', false);
    },

    [`${SET_CONSOLIDATION_ADDONS_METHODS}_SUCCESS`]: (state, { payload }) => {
      return state
        .setIn(
          ['cart', 'addons_codes'],
          payload.get('data').get('addons_codes'),
        )
        .set('isLoading', false);
    },

    [`${CREATE_CUSTOM_DECLARATION}_SUCCESS`]: (state, { payload }) =>
      state
        .updateIn(['cart', 'customs_informations'], () => payload.get('data'))
        .set('isLoading', false),

    [`${CONSOLIDATE_CART}_SUCCESS`]: (state) => state.set('isLoading', false),

    [DROP_CART]: () => initialState,

    [combineActions(
      `${GET_CART}_REQUEST`,
      `${DELETE_CART}_REQUEST`,
      `${ADD_PARCELS}_REQUEST`,
      `${IMPORT_CUSTOM_INFORMATION}_REQUEST`,
      `${CONSOLIDATE_CART}_REQUEST`,
      `${IMPORT_CUSTOMS_DECLARATION}_REQUEST`,
      `${SET_PARTNER_ID}_REQUEST`,
    )]: (state) => state.set('isLoading', true),

    [combineActions(
      `${GET_CART}_ERROR`,
      `${DELETE_CART}_SUCCESS`,
      `${DELETE_CART}_ERROR`,
      `${ADD_PARCELS}_ERROR`,
      `${IMPORT_CUSTOM_INFORMATION}_ERROR`,
      `${CONSOLIDATE_CART}_ERROR`,
      `${SET_ADDONS_METHODS}_ERROR`,
      `${DELETE_ADDONS_METHODS}_ERROR`,
      `${SET_CONSOLIDATION_ADDONS_METHODS}_ERROR`,
      `${IMPORT_CUSTOMS_DECLARATION}_SUCCESS`,
      `${IMPORT_CUSTOMS_DECLARATION}_ERROR`,
      `${SET_PARTNER_ID}_SUCCESS`,
      `${SET_PARTNER_ID}_ERROR`,
    )]: (state) => state.set('isLoading', false),
  },
  initialState,
);

export default cartReducer;
